import OutlinedInput from "@mui/material/OutlinedInput/OutlinedInput";
import  TextField  from "@mui/material/TextField/TextField";
import { styled } from '@mui/system';

export const InputText = styled(TextField)`
font-family: 'Lexend Deca';
margin-bottom: 24px;
& label{
    color: #a8a8a8;
}
& .MuiInput-underline::before {
  border-bottom: 1px #a8a8a8 solid !important;
}
& .MuiInput-underline::after {
  border-color: #FC730D;
}
& label.Mui-focused{
    color: #FC730D !important;
}
`;

export const NewsletterInput = styled(OutlinedInput)`
background-color: white;
border-radius: 16px !important;
margin-bottom: 12px;

&.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #ebebeb;
}
& .MuiOutlinedInput-notchedOutline {
  border: 2px solid #ebebeb ;
}

&:hover .MuiOutlinedInput-notchedOutline {
  border: 2px solid #FC730D ;
}

`