import Grid from "@mui/material/Grid";
import PostListComponent from "./postList/postList";
import { SectionContainer } from "../ui/container";
import { SectionTitleTypography } from "../ui/typography";

export default function PostsSectionComponent(props:any){
    const { loading, posts, title, onclickPost } = props;
    return (
        <>
        <SectionContainer>
            <SectionTitleTypography  >
            { title }
            </SectionTitleTypography>
            <Grid container spacing={2}>
            
            {
            loading? 
            (
            ['1','2', '3'].map((d) => {
                return (
                <Grid item xs={12} md={4} key={d} >
                    <PostListComponent
                        loading = { true }
                        thumbnail = {{...{dataUrl: ''} }}
                        writer = { '' }
                        post = {{ internal: '', title: '', estimatedTime: '', description: ''  }}
                        route = { '' }
                    />
                </Grid>
                )
            })
            ):(
            posts?.sort((a: any, b: any) => { 
                
                return new Date(b.internal.createdAt).valueOf() - new Date(a.internal.createdAt).valueOf();
            
            }).map((_post: any) => {
                console.log(_post)
                return (
                <Grid item xs={12} md={4} key={_post.tag } >
                    <PostListComponent 
                        thumbnail = { _post.media?.thumbnail }
                        breadcrumb = { _post.breadCrum.name }
                        writer = { _post.writer }
                        post = {{ internal: _post.internal, title: _post.title, estimatedTime: _post.estimatedTime, description: _post.description  }}
                        route = { _post.tag }
                        loading = { loading }
                        onClick = { onclickPost }
                    />
                </Grid>
                );
                })
            )
            }
            </Grid>
        </SectionContainer>
        </>
    )
}