import { styled } from '@mui/system';
import Container from '@mui/material/Container';
import { Box, Card } from '@mui/material';

export const BodyContainer = styled(Container)`
&& {
    margin-top: 90px;
}
`;

export const PostViewContainer = styled(BodyContainer)`
&& {

}
`;

export const PostListContainer = styled(Container)`
&&{
    margin-bottom: 2em;   
    padding-left: 0px !important;
    padding-right: 0px !important;
}
`;

export const MainPostInfoContainer = styled(Container)`
    margin-top: 2em;
    padding-left: 0;
    padding-right: 0;
    ${props => props.theme.breakpoints.down("md")} {
        margin-top: 0;
      }

`;

export const SectionContainer = styled(Container)`
    &&{
        padding-left: 0;
        padding-right: 0;
    }
`;


export const ModalContainer =  styled(Box)`
    padding-top: 24px;
    padding-bottom: 48px;
    padding-left: 48px !important;
    padding-right: 48px !important;
    display: flex;
    flex-direction: column;
    ${props => props.theme.breakpoints.down("md")} {
        padding-top: 12px;
        padding-bottom: 24px;
        padding-left: 24px !important;
        padding-right: 24px !important;
    }
`;

export const ModalColumns = styled(Card)`
    display: flex;
`

export const SocialShareContainer = styled(Box)<{
    visibility: string
}>`
    display: flex;
    align-items: center;
    float: right;
    display: ${(props) => (props.visibility ==='true' ? "flex": "none")};
    ${props => props.theme.breakpoints.down("md")} {
        display: none;
    }

`


