import type { AnyAction } from "redux";
import type { SubscribeStore } from "../../domain/subscribe/subscribeStore";
import * as actionTypes from "./subscribeActionTypes";

type SubscribeStoreState = Omit<SubscribeStore, "updateSubscribe" | "updateNewsletter">

const INITIAL_STATE: SubscribeStoreState = {
    subscribe: {
        name: '',
        lastName: '',
        email: ''
    },
    succcess: false,
    newsletter: {
        name: '',
        lastName: '',
        email: ''
    }

}

const subscribeReducer = (state: SubscribeStoreState = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case actionTypes.UPDATE_SUBSCRIBE:
            return { ...state, subscribe: action.subscribe }
        case actionTypes.UPDATE_NEWSLETTER:
            return { ...state, newsletter: action.newsletter }
        default:
            return state;
    }
}

export { subscribeReducer };
export type { SubscribeStoreState };