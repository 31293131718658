import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { FooterContainer } from "../../ui/footer";
import SocialLinks from "./socialLinks";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
export default function Footer() {

    const socialLinks = [
        {
        form: FacebookRoundedIcon,
        href:"https://www.facebook.com/ConomyHQ",
        alt: "facebook"
        }, 
        {
        form: InstagramIcon,
        href:"https://www.instagram.com/conomy_app/",
        alt: "instagram" 
        }, 
        {
        form: LinkedInIcon,
        href:"https://www.linkedin.com/company/conomyapp",
        alt: "linkedin"
        }];
    return (
        <>
        <Container maxWidth="lg">
            <FooterContainer>
                <Grid container spacing={0}>
                    <Grid item xs={12} md={2} />
                    <Grid item xs={12} md={5} />
                    <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={7}>
                                <a href='https://conomy.app' target="_blank">
                                    <img className="logoFooter" src='/images/logo-All-orange.svg' alt="logo-conomy" />
                                </a>
                                <a className="link-footer" target="_blank" href='https://conomy.app'>Sitio web</a>
                                <a className="link-footer" target="_blank" href='https://developer.conomy.app'>Desarrolladores</a>
                                <a className="link-footer" target="_blank" href='mailto:hola@conomyhq.com'>hola@conomyhq.com</a>
                                <Typography className="link-footer" >Copyright © 2022.</Typography>
                                <SocialLinks
                                    socialLinks = { socialLinks }
                                />
                            </Grid>
                            <Box component={Grid} item sx={{ display:{ xs: 'none', sm: 'none', md: 'block'} }} md={5}>
                            <a href='mailto:hola@conomyhq.com' target="_blank">
                                <img className="character" src='/images/chat.svg' alt="personaje-conomy" />
                            </a>
                            </Box>
                        </Grid>
                    </Grid>    
                </Grid>
            </FooterContainer>
        </Container>
        </>
    )
}
