
import { LogoPuntoConomy } from '../../shared/ui/logoImage';
import { ConomyTypography, DotConomyTypography, TitleContainer, DotTypography } from '../../shared/ui/title';


export default function TitleConmponent (props: any){
    const { src } = props;
    return (
        <>
        <TitleContainer>
            <ConomyTypography>Punto</ConomyTypography> 
            <DotConomyTypography>.</DotConomyTypography>  
            <ConomyTypography>conomy</ConomyTypography>
        </TitleContainer> 
        </>
    )
}