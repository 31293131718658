import React from 'react';
import ReactDOM from 'react-dom/client';
import { hydrate, render } from "react-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppView from './app/main/view/appView';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { firebaseConfig } from './fireBaseConfig';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(

//     <AppView />

// );

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(<AppView />, rootElement);
} else {
  render(<AppView />, rootElement);
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
