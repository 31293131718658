
import MailchimpSubscribe, { EmailFormFields } from "react-mailchimp-subscribe";
import { SectionContainer } from "../../ui/container";



export default function MailchimpComponent(props: any){
    const { postUrl, form, store, callback } = props; 
    const Form = form;
    return (
        <>
        <SectionContainer>
            <MailchimpSubscribe
                url = { postUrl }
                render = { ({ subscribe, status, message }) => ( 
                <Form
                    callback = { callback } 
                    store = { store } 
                    status = { status } 
                    message = { message } 
                    onValidated = { (formData: EmailFormFields) => subscribe(formData) }
                    />               
                )} 
            />
        </SectionContainer>
        </>
    )
}
