import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { postsReducer } from "../../shared/data/posts/postsReducer";
import { navBarReducer } from "../../shared/data/navBar/navBarReducer";
import { postReducer } from "../../shared/data/post/postReducer";
import { modalReducer } from "../../shared/data/modal/modalReducer";
import { subscribeReducer } from "../../shared/data/subscribe/subscribeReducer";
import { errorReducer } from "../../shared/data/error/errorReducer";

const rootReducer = combineReducers({
  posts: postsReducer,
  post: postReducer,
  navBar: navBarReducer,
  modal: modalReducer,
  subscribe: subscribeReducer,
  error: errorReducer
});

const appStoreImplementation = createStore(rootReducer, applyMiddleware(thunk));

type AppRootState = ReturnType<typeof appStoreImplementation.getState>;

export { appStoreImplementation };
export type { AppRootState };
