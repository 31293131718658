import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/system';
import Toolbar from '@mui/material/Toolbar';

export const NavBar = styled(AppBar)<{
    topshadow: string
}>`
&& {
    height: 70px;
    background-color: #fff;
    -webkit-box-shadow:${(props) => (props.topshadow ==='false' ? "0 8px 64px 0px rgb(21 42 65 / 5%)": "0 0px 0px 0px rgb(21 0 0 / 0%)")};
    box-shadow: ${(props) => (props.topshadow ==='false' ? "0 8px 64px 0px rgb(21 42 65 / 5%)": "0 0px 0px 0px rgb(21 0 0 / 0%)")};
    border-bottom: ${(props) => (props.topshadow ==='false' ? "1px solid #FAFBFC": "none")};
    margin:auto;
    z-index: 10;
    transition: box-shadow cubic-bezier(0.4, 0, 1, 1) 1s;
}`

export const NavToolBar = styled(Toolbar)`
&& {
    height: 70px;
    line-height: 1;
    margin: auto;
    clear: both;
    padding-left:0;
    padding-right:0;
}
`;

