import React from "react";
import { isTemplateTail } from "typescript";
import { InputText } from "../../ui/inputText";


export default function InputFieldComponent(props: any){
    
    const { onChangeHandler, label, value, placeholder, type } = props;
   
    const validate = () => {
        switch(type){
            case 'email':
                return 
        }
    }

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        
        onChangeHandler(event.target.value);
    }
    return (
        <InputText
            label = { label }
            value = { value }
            placeholder = { placeholder }
            type = { type }
            onChange = { handleValueChange }
            variant  = "standard"
            fullWidth
        />
    )
}