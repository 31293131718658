import Skeleton from "@mui/material/Skeleton";
import { PostBreadCrumbTypography } from "../ui/typography";



export default function PostBreadCrumbComponent (props: any){

    const { loading, breadcrumb } = props;

    return (
        <>
        <PostBreadCrumbTypography >
            {loading? <Skeleton animation='wave' sx={{ width:'100px' }} /> : breadcrumb }
        </PostBreadCrumbTypography>
        </>
    )

}