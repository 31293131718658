import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { BaseLayout } from '../../shared/layouts';

import { Provider } from "react-redux";

import { appStoreImplementation } from "../data/appStoreImplementation";


const AppView = () => {

  return (
      <Provider store={appStoreImplementation}>
        <BrowserRouter>
          <BaseLayout children={undefined} />
        </BrowserRouter>
      </Provider>
  );
};


export default AppView;
