import React from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppRootState } from "../../../main/data/appStoreImplementation";
import { Subscribe } from "../../domain/subscribe/subscribeEntity";
import { SubscribeStore } from "../../domain/subscribe/subscribeStore";
import { updateNewsletterAction, updateSubscribeAction } from "./subscribeActions";
import { SubscribeStoreState } from "./subscribeReducer";

const subscribeSelector = (state: AppRootState) => state.subscribe;

const useSubscribeStoreImplementation = (): SubscribeStore => {
    const { subscribe, succcess, newsletter } = useSelector<
        AppRootState,
        SubscribeStoreState
    >(subscribeSelector)

    const dispatch = useDispatch();

    const updateSubscribe = React.useCallback(
        (subscribe: Subscribe) => updateSubscribeAction(subscribe)(dispatch),
        [dispatch]
    )

    const updateNewsletter = React.useCallback(
        (newsletter: Subscribe) => updateNewsletterAction(newsletter)(dispatch),
        [dispatch]
    )

    return {
        subscribe,
        succcess,
        newsletter,
        updateSubscribe,
        updateNewsletter
    }

}

export { useSubscribeStoreImplementation }
