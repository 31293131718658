import Typography from '@mui/material/Typography';

import { styled } from '@mui/system';



export const WriterTypography = styled(Typography)`
    &&{
    text-transform: uppercase;
    line-height: 1.4em;
    letter-spacing: 0.2px;
    font-weight: 800;
    font-size: 0.75rem;
    font-family: Mulish;
    color: #36384C;
    }
`;

export const DatePostTypography = styled(Typography)`
&&{
    text-transform: uppercase;
    line-height: 1.4em;
    letter-spacing: 0.2px;
    font-weight: 600;
    font-size: 0.75rem;
    color: #838383;
    font-family:Mulish; 
}
`;

export const SectionTitleTypography = styled(Typography)`
&&{ 
    font-family: 'Lexend Deca';  
    font-size: 2.3em;
    line-height: 1.2em;
    font-weight: 800;
    margin-top: 0.5em;
    margin-bottom: 1em;
    color: #36384C;
}
`;

export const PostViewTitleTypography = styled(SectionTitleTypography)`
&&{
    // margin-top: 0;
}
`

export const PostListTitleTypography = styled(Typography)`
&&{ 
    font-family: Lexend Deca;  
    font-size: 1.1em;
    font-weight: 800;
    padding-top: 0.5rem;
    display: -webkit-box;
    overflow: hidden;
    Webkit-box orient: vertical;
    WebkitLine-clamp: 1;
    color: #36384C;
}
`;

export const MainPostTitleTypography = styled(Typography)`
    font-family: 'Lexend Deca'; 
    font-size: 2.1em;
    line-height: 1.2em;
    font-weight: 800;
    // padding-top: 0.5em;
    color: #36384C;
`;

export const ModalTitleTypography = styled(Typography)`
&&{ 
    font-family: 'Lexend Deca';  
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: 800;
    margin-top: 0.5em;
    margin-bottom: 2em;
    color: #36384C;
}`

export const BreadCrumbTypography = styled(Typography)`
&&{
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 12px;
    color: rgb(252, 115, 13);
    font-weight: 400;
    font-family: 'Lexend Deca';
    letter-spacing: 0.104em;
}
`

export const PostBreadCrumbTypography = styled(Typography)`
&&{
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 14px;
    color: rgb(252, 115, 13);
    font-weight: 400;
    font-family: 'Lexend Deca';
    letter-spacing: 0.060em;
    padding-top: 1rem;
    ${props => props.theme.breakpoints.down("md")} {
        padding-bottom: 0.5rem;
        margin-top: 0rem;
    }
}
`
export const NewsletterTypography = styled (Typography)`
font-family: Lexend Deca;  
font-size: 1.2em;
font-weight: 800;
padding-top: 0.5rem;
padding-bottom: 0.5rem;
color: #36384C;
`

export const HeaderTitleTypography = styled(Typography)<{ 
    displaytext: string
 }>`
    text-align: left;
    font-family: 'Mulish';
    color: #838383;
    font-weight: 700;
    letter-spacing: 1px;
    flex-grow: 1;
    text-transform: uppercase;
    font-size:12px;
    margin-left: 4.6rem;
    padding-right: 1rem;
    visibility: ${(props) => (props.displaytext ==='true' ? "visible": "hidden")};
    ${props => props.theme.breakpoints.down("md")} {
        visibility: hidden;
        margin-left: 0rem;
    }
    transition: visibility cubic-bezier(0.4, 0, 1, 1) 1s;
`
export const ShareTypography = styled(Typography)`
    margin: 0;
    font-family: 'Lexend Deca';
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    margin-right: 1rem;
    font-size: 12px;
    color: #838383;
`