
import debounce from "lodash.debounce";
import type { PostStore } from "../../domain/post/postStore";


type GetPostStore = Pick<PostStore, 
    "loadPost" | "mainPostTag" | "postTag"
    >;

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);


const updatePostUseCase = (
    store: GetPostStore, 
    mainPost: boolean
    ) => {
        
        const id = mainPost? store.mainPostTag : store.postTag;
        if (!id) return
        const tag = id; 

        return debouncedTask(() => store.loadPost(tag!, mainPost));
    };



export { updatePostUseCase };
export type { GetPostStore };