import type { AnyAction } from "redux";
import type {  ModalStore } from "../../domain/modal/modalStore";

import * as actionTypes from "./modalActionTypes";

type ModalStoreState = Omit<ModalStore, "updateModal" >;

const INITIAL_STATE: ModalStoreState = {
    open: false
}

const modalReducer = (state: ModalStoreState = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case actionTypes.SET_MODAL:
            return { ...state, open: action.modal };
        default:
            return state;
    }
}

export { modalReducer };
export type { ModalStoreState };