import type { AnyAction } from "redux";
import { ErrorStore } from "../../domain/error/errorStore";
import * as actionTypes from "./errorActionTypes";

type ErrorStoreState = Omit<ErrorStore, "updateError">

const INITIAL_STATE: ErrorStoreState = {
    error: false
}

const errorReducer = (state: ErrorStoreState = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case actionTypes.UPATE_ERROR:
            return {...state, error: action.isError }
        default:
            return state;
    }
}

export { errorReducer };
export type { ErrorStoreState };
