import React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { PostsStoreState } from "./postsReducer";
import {
    getPostsAction,

} from "./postsActions";

import { AppRootState } from "../../../main/data/appStoreImplementation";

import { PostsStore } from "../../domain/posts/postsStore";


const postsSelector = (state: AppRootState) => state.posts;

const usePostsStoreImplementation = (): PostsStore => {
    const { posts, mostReadPosts, mostReadPostsLoading, postsLoading } = useSelector<
        AppRootState,
        PostsStoreState
    >(postsSelector);

    const dispatch = useDispatch();

    const loadPosts = React.useCallback(
        (homePost: boolean) => getPostsAction(homePost)(dispatch),
        [dispatch]
    );

    return {
        posts,
        mostReadPosts,
        postsLoading,
        mostReadPostsLoading,
        loadPosts
    }
};

export { usePostsStoreImplementation };