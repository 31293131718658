import  PATHS  from './url';

import HomeView from '../../../home/view/homeView';
import Post from '../../../post/view/postView';
import { Error404View } from '../../view/error404View';

const routes = [
  {
    path: PATHS.HOME,
    Component: HomeView,
    exact: true,
  },
  {
    path: PATHS.POST,
    Component: Post,
  },
  {
    path: '*',
    Component: Error404View,
  }
];

export default routes;
