import React from "react";
import { SubscribeStore } from "../domain/subscribe/subscribeStore";
import { clearNewsletterEmailUseCase } from "../useCases/subscribe/clearNewsletterUseCase";
import { clearSubscribeUseCase } from "../useCases/subscribe/clearSubscribeUseCase";
import { setNewsletterEmailUseCase } from "../useCases/subscribe/setEmailNewsletterUseCase";
import { setEmailUseCase } from "../useCases/subscribe/setEmailUseCase";
import { setLastNameUseCase } from "../useCases/subscribe/setLastNameUseCase";
import { setNameUseCase } from "../useCases/subscribe/setNameUseCase";


function useSubscribeViewModel(store: SubscribeStore){

    const setEmail = React.useCallback(
        function(value: string){
            setEmailUseCase({
                updateSubscribe: store.updateSubscribe,
                subscribe: store.subscribe
            }, value)
        },[store.subscribe, store.updateSubscribe]
    )

    const setNewsletterEmail = React.useCallback(
        function(value: string){
            setNewsletterEmailUseCase({
                updateNewsletter: store.updateNewsletter,
                newsletter: store.newsletter
            }, value)
        },[store.newsletter, store.updateNewsletter]
    )
    

    const setName = React.useCallback(
        function(value: string){
            setNameUseCase({
                updateSubscribe: store.updateSubscribe,
                subscribe: store.subscribe
            }, value)
        },[store.subscribe, store.updateSubscribe]
    )

    const setLastName = React.useCallback(
        function(value: string){
            setLastNameUseCase({
                updateSubscribe: store.updateSubscribe,
                subscribe: store.subscribe
            }, value)
        },[store.subscribe, store.updateSubscribe]
    )

    const clearSubscribeForm = React.useCallback(
        function(){
            clearSubscribeUseCase({
                subscribe: store.subscribe,
                updateSubscribe:store.updateSubscribe
            })
        }, [ store.subscribe, store.updateSubscribe ]
    )

    const clearNewsletterForm = React.useCallback(
        function(){
            clearNewsletterEmailUseCase({
                newsletter: store.newsletter,
                updateNewsletter:store.updateNewsletter,
            })
        }, [ store.newsletter, store.updateNewsletter ]
    )

    return {
        subscribe: store.subscribe,
        newsletter: store.newsletter,
        setEmail, 
        setName, 
        setLastName,
        clearSubscribeForm,
        setNewsletterEmail,
        clearNewsletterForm
    }
}

export { useSubscribeViewModel };

