
import { createTheme } from '@mui/material/styles';

const ConomyTheme = createTheme({

  typography: {
    fontFamily: 'Mulish, sans-serif',
  },
  components: {
    MuiOutlinedInput:{
      styleOverrides:{

          root: {
            "&:hover $notchedOutline": {
              borderColor: '#fff'
            },
            "&$focused $notchedOutline": {
              borderColor: '#fff'
            }
          },
          notchedOutline: {
            borderColor: '#fff'
          }
        
      }
    },
    MuiSkeleton:{
      styleOverrides:{
        root:{
          backgroundColor: '#efefef',
          animation: "wave",
          borderRadius: '12px',
          border: '1px solid #FAFBFC',
          boxShadow: '0px 8px 64px 0px rgb(21 42 65 / 5%)'
        }
      }
      
    },
    MuiCssBaseline: {
      styleOverrides: `
        h1, h2, h3 {
          font-family: 'Lexend Deca', sans-serif, 'Baloo 2';
        },
        body {
          color: #302C3A;
          background-color; #fff;
          line-height: 1!important;
        };
      `,
    },
  },
});


export default ConomyTheme;