import Routes from '../../routes';
import ConomyTheme from '../../ui/conomyTheme';
import layoutType from '../types/layout';

import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider,ThemeProvider } from '@mui/material/styles';

import NavBarView from '../../view/navBarView';
import Footer from '../../components/footer/footer';
import { useErrorStoreImplementation } from '../../data/error/errorStoreImplementation';
import { useErrorViewModel } from '../../controller/errorViewModel';
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';



const BaseLayout = ({children}: layoutType) => {


  const errorStore = useErrorStoreImplementation();
  const { error, clearError } = useErrorViewModel(errorStore);

  const navigate = useNavigate();

  const isMounted = useRef(false)
  React.useEffect(() => {
    if(!isMounted.current){
      clearError();
      isMounted.current = true;
    }
  },[]);

  React.useEffect(()=>{
    if (error){
      navigate('/404')
    }
  },[error])


  return (
    <>
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={ConomyTheme}>
          <CssBaseline />
           <NavBarView /> 
            <Routes />
            <Footer />
      </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default BaseLayout;
