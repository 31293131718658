import React from 'react';
import { PublicLayout } from '../../shared/layouts';

import { usePostsViewModel } from '../controllers/postsViewModel';
import { usePostsStoreImplementation } from '../../shared/data/posts/postsStoreImplementation';

import MainPostComponent from '../components/mainPost/mainPostComponent';

import Divider from '@mui/material/Divider';
import { usePostStoreImplementation } from '../../shared/data/post/postStoreImplementation';
import { useMainPostViewModel } from '../controllers/mainPostViewModel';
import PostsSectionComponent from '../../shared/components/PostsSectionComponent';
import TitleComponent from '../components/titleComponent';
import NewsletterComponent from '../../shared/components/newsletter/newsletter';
import { useSubscribeViewModel } from '../../shared/controller/subscribeViewModel';
import { useSubscribeStoreImplementation } from '../../shared/data/subscribe/subscribeStoreImplementation';
import { usePostViewModel } from '../../shared/controller/postViewModel';
import { useErrorStoreImplementation } from '../../shared/data/error/errorStoreImplementation';

import { useErrorViewModel } from '../../shared/controller/errorViewModel';
import SEOComponent from '../../shared/components/seo';

const HomeView = () => {
  const postsStore = usePostsStoreImplementation();
  const postStore = usePostStoreImplementation();
  const {
    posts,
    getPosts,
    postLoading
  } = usePostsViewModel(postsStore, postStore.mainPostTag);

  const {
    mainPost,
    mainPostLoading,
    getMainPost,
  } = useMainPostViewModel(postStore);

  const newsLetterStore = useSubscribeStoreImplementation();

  const {
      clearNewsletterForm,
      setNewsletterEmail,
      newsletter
  } = useSubscribeViewModel(newsLetterStore);

  const {
    clearPostTitle,
    clearPostSummary
  } = usePostViewModel(postStore)

  const errorStore = useErrorStoreImplementation();
  const {
    error,
    clearError,
    setError,
  } = useErrorViewModel(errorStore)

  React.useEffect(() => {
    getPosts();
    getMainPost();
    clearPostTitle();
    clearPostSummary();
    clearError();
  }, []);



  return (
    <>
    <SEOComponent
    title = "Punto Conomy"
    content = "el blog que ayuda para tus finanzas personales"
    url = { window.location.href }
    image = "/images/punto.svg"
  />
      <PublicLayout>
        <TitleComponent
        />

        <MainPostComponent
          post = { mainPost }
          loading = { mainPostLoading }
        />

        <Divider sx={{ marginTop: '3em', marginBottom: '3em' }} />

        <PostsSectionComponent 
          loading = { postLoading }
          posts = { posts }
          title = "Lo último en conomy 🤓" 
        />

      <NewsletterComponent
       store = {{email:{value: newsletter.email, set: setNewsletterEmail}}}
       callback = {{clearFields: clearNewsletterForm}}
      />
      </PublicLayout>
    </>
    );
  };
  
export default HomeView;