
import { CardImage, CardContainer, ImageSkeleton } from '../ui/cardImage';

export default function CardImageComponent(props: any){
    const { image, loading } = props;
    return(
        <>
        {
            loading?
            (
            <ImageSkeleton 
            animation="wave" 
            variant="rectangular" />
            ) : (
            <CardContainer>
                <CardImage image={image}
                    {...{component:"img"}}
                />
            </CardContainer>
            )
        }
        </>
    )
}