import { styled } from '@mui/system';
import Button from '@mui/material/Button';

export const OrangeButton = styled(Button)`
    && {
    padding: 12px 40px;
    margin: 0 20px 0 0;
    letter-spacing: 0.5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: 'Lexend Deca';
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    background-color: #FC730D;
    border: 1px #FC730D solid;
    text-transform: none;
    line-height: 1!important;
    }
`;


export const HeaderOrangeButton = styled(Button)`
    && {
    letter-spacing: 0.5px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-family: 'Lexend Deca';
    font-weight: 400;
    text-transform: none;
    color: #fff;
    background-color: #FC730D;
    padding: 0 22px;
    margin: 19px 0 19px 25px;
    border: 1px #FC730D solid;
    height: 32px;
    line-height: 30px;
    font-size: 14px;
    display: inline-block;
    float: left;
    position: relative;

    }
`;

export const SubmitModalButton = styled(OrangeButton)`
&&{
    margin: 40px 0 0 0;
}
`