import debounce from "lodash.debounce";

import type { NavBarStore } from "../../domain/navBar/navBarStore";

type UpdateNavBarStore = Pick<
    NavBarStore,
    'onTop' | 'setOnTop' | 'updateOnTop'
>;

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

const updateOnTopNavBarStoreUseCase = (
    store: UpdateNavBarStore,
    updateBy: (onTop: boolean) => boolean
    ) => {
        const updatedOnTop = updateBy(store.onTop)

        store.setOnTop(updatedOnTop);

        return debouncedTask( () => store.updateOnTop(updatedOnTop))
    };

export { updateOnTopNavBarStoreUseCase };
export type { UpdateNavBarStore };