import React from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppRootState } from "../../../main/data/appStoreImplementation";
import { ErrorStore } from "../../domain/error/errorStore";
import { updateErrorAction } from "./errorActions";
import { ErrorStoreState } from "./errorReducer";


const errorSelector = (state: AppRootState) => state.error;

const useErrorStoreImplementation = (): ErrorStore =>{
    const { error } = useSelector<
    AppRootState,
    ErrorStoreState
    >(errorSelector);

    const dispatch = useDispatch();

    const updateError = React.useCallback(
        (isError: boolean) => updateErrorAction(isError)(dispatch),
        [dispatch]
    )

    return {
        error,
        updateError
    };
};

export { useErrorStoreImplementation }
