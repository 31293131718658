import layoutType from '../types/layout';
import { PostViewContainer } from '../../ui/container';

const PostLayout = ({children}: layoutType) => {
    return (
        <>
            <PostViewContainer maxWidth="sm">
                {children}
            </PostViewContainer>
        </>
    );
}

export default PostLayout;