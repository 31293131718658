import { styled } from '@mui/system';

export const FooterContainer = styled('footer')`
    width: 100%;
    float: left;
    clear: both;
    background-color: ;#fff;
    position: relative;
    margin-top: 4rem;
    padding-bottom: 4rem;
    ${props => props.theme.breakpoints.down("md")} {
        align-items: center !important;
    }
`;