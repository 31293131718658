import React from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppRootState } from "../../../main/data/appStoreImplementation";
import type { NavBarStore } from "../../domain/navBar/navBarStore";
import type { NavBarStoreState } from "./navBarReducer";
import { setOnTopAction, updateOnTopAction } from "./navBarActions";

const navBarSelector = (state: AppRootState) => state.navBar;

const useNavBarStoreImplementation = (): NavBarStore => {
    const { onTop } = useSelector<
    AppRootState,
    NavBarStoreState
    >(navBarSelector);

    const dispatch = useDispatch();

    const setOnTop = React.useCallback(
        (onTop: boolean) => setOnTopAction(onTop)(dispatch),
        [dispatch]
    );

    const updateOnTop = React.useCallback(
        (onTop: boolean) => updateOnTopAction(onTop)(dispatch),
        [dispatch]
    );

    return {
        onTop,
        setOnTop,
        updateOnTop
    };
};

export { useNavBarStoreImplementation };

