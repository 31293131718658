import ReactMarkdown from 'react-markdown';
import Skeleton from '@mui/material/Skeleton';

export default function MainPostSubtitleComponent(props: any){
    const { loading, description } = props;
    return(
        <>
        { loading ? 
            (
            <Skeleton variant="rectangular" width="100%" height="150px" sx={{ marginTop: '1rem' }}/>

            ):(
            <ReactMarkdown className='subtitle'>
                { description! }
            </ReactMarkdown>
            ) 
        }
        </>
    )
}