import { Container, IconButton } from "@mui/material";

export default function SocialLinksFooter(props:any){
    const { socialLinks } = props;
    return (
        <>
        <Container sx={{ paddingLeft: '0px' }}>
        {
        socialLinks.map((_link: any) => {
            const Form = _link.form;
            return(
                    <IconButton href={  _link.href } key={ _link.alt }>
                        <Form />    
                    </IconButton>
            )

        })}
        </Container>
        </>
    )
}