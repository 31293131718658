import debounce from "lodash.debounce";
import type { PostStore } from "../../domain/post/postStore";

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

type PostTagUpdateStore = Pick<PostStore, 'updatePostTag' | 'postTag' >;

const updatePostTagUseCase = async (
    store: PostTagUpdateStore,
    tag: string
) => { 
  
    return debouncedTask( () => store.updatePostTag(tag))
};


export { updatePostTagUseCase }
export type { PostTagUpdateStore };