import debounce from "lodash.debounce";
import { ModalStore } from "../../domain/modal/modalStore";

type UpdateModalOpenStore = Pick<
    ModalStore,
    "open" | "updateModal"
>

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

const updateModalUseCase = (
    store: UpdateModalOpenStore,
    updateBy: () => boolean
) => {

    const updatedModalValue = updateBy();

    return debouncedTask(() => store.updateModal(updatedModalValue));
}

export { updateModalUseCase };
export type { UpdateModalOpenStore };