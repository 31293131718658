import React from 'react';
import { Routes, Route } from 'react-router-dom';

import publicRoutes from './configs/publicConfig';

const Router = () => {
  return (
    <Routes>
      {publicRoutes.map(({ path, Component, exact = false }, key) => (
  
          <Route key={key} path={path} element={< Component />} />
      ))}
    </Routes>
  );
};

export default Router;
