import type { AnyAction } from "redux";
import type { NavBarStore } from "../../domain/navBar/navBarStore";
import * as actionTypes from './navBarActionTypes'

type NavBarStoreState = Omit<NavBarStore, "setOnTop"| "updateOnTop">

const INITIAL_STATE: NavBarStoreState = {
    onTop: true
};

const navBarReducer = (state: NavBarStoreState = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case actionTypes.SET_ON_TOP:
            return {...state, onTop: action.onTop};
        case actionTypes.UPDATE_ON_TOP:
            return {...state, onTop: action.onTop};
        default:
            return state;
    }
}

export { navBarReducer }
export type { NavBarStoreState };