import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import PostInfoComponent from '../../../shared/components/postInfo/postInfo';
import { MainPostInfoContainer, SectionContainer } from '../../../shared/ui/container';
import MainPostSubtitleComponent from './mainPostSubtitleComponent';
import MainPostTitleComponent from './mainPostTitleComponent';
import useMediaQuery from '@mui/material/useMediaQuery/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import CardMainComponent from '../../../shared/components/cardMainImage';
import PostBreadCrumbComponent from '../../../shared/components/postBreadCrumb';


export default function MainPostComponent(props: any) {
    const { post, loading, onClick } = props;
    const theme = useTheme();
    const xsMediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
    const breadCtumb = post?.breadCrum?.name?.toLowerCase().split(' ').join('_')
    return (
        <>
        <SectionContainer>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Link to={"/"+  breadCtumb?.replace(/[\u0300-\u036f]/g, "") +'/' + post?.tag} >
                        <CardMainComponent
                            loading = { loading } 
                            image={post?.media.thumbnail.dataUrl! || '' }
                            />
                    </Link>
                </Grid>
                <Grid item xs={12} md={6}>
                <MainPostInfoContainer  >
                { xsMediaQuery &&
                    <PostInfoComponent 
                        writerName     = { post?.writer.name || '' } 
                        writerLastName = { post?.writer.last_name || '' }
                        writerImage    = { post?.writer.image?.dataUrl }
                        estimatedTime  = { post?.estimatedTime.toString() + ' min'  }
                        date           = { post?.internal.updatedAt }
                        loading        = { loading }
                    />
                }  
                <PostBreadCrumbComponent
                        loading     = { loading }
                        breadcrumb = { post?.breadCrum?.name }
                    />  
                <Link to={"/"+  breadCtumb?.replace(/[\u0300-\u036f]/g, "") +'/' + post?.tag} >
                        <MainPostTitleComponent
                            loading = { loading }
                            title   = { post?.title } 
                        />
                    </Link>
                    <MainPostSubtitleComponent
                        loading     = { loading }
                        description = { post?.description }
                    />
                { !xsMediaQuery &&
                    <PostInfoComponent 
                        writerName     = { post?.writer.name || '' } 
                        writerLastName = { post?.writer.last_name || '' }
                        writerImage    = { post?.writer.image?.dataUrl }
                        estimatedTime  = { post?.estimatedTime.toString() + ' min'  }
                        date           = { post?.internal.updatedAt }
                        loading        = { loading }
                    />
                }           
                </MainPostInfoContainer>
                </Grid>
            </Grid>
        </SectionContainer>
        </>
    );
};



