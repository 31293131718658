import debounce from "lodash.debounce";
import { ErrorStore } from "../../domain/error/errorStore";


type UpdateErrorStore = Pick<
    ErrorStore,
    "error" | "updateError"
>

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

const updateErrorUseCase = (
    store: UpdateErrorStore,
    updateBy: () => boolean
) => {
    const updatedError = updateBy();

    return debouncedTask(() => store.updateError(updatedError));
}

export { updateErrorUseCase };
export type { UpdateErrorStore };