import Skeleton from "@mui/material/Skeleton";
import { DatePostTypography } from "../../ui/typography";

export default function DatePostComponent(props: any){
    const { loading, date, estimatedTime } = props;
    const dateFormat = new Date(date);
    const humanReadableDate = dateFormat.toLocaleDateString('es-ES', { year: 'numeric', month: 'short', day: 'numeric' });
    return(
        <>
        <DatePostTypography  >
                { loading ? <Skeleton animation='wave' sx={{ width:'100px' }}/> : humanReadableDate + ' · ' + estimatedTime }
        </DatePostTypography>
        </>
    )
}