import React from 'react';

import type { PostsStore } from '../../shared/domain/posts/postsStore';
import { getPostsHomeUseCase } from '../useCases/getPostsHomeUseCase';



function usePostsViewModel(store: PostsStore, mainPostTag: string){
  

    const getPosts = React.useCallback(
        function() {
            getPostsHomeUseCase({
                loadPosts: store.loadPosts
            });
        },
        [store]
    );

    return {
        posts: store.posts?.filter( d => d.tag !== mainPostTag),
        getPosts,
        postLoading: store.postsLoading
    };
}

export { usePostsViewModel };