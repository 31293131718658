import { Card, FormControl, styled } from "@mui/material";


export const CardNewsLetter = styled(Card)`
    &&{
        display: flex;
        background-color: #FFFAF4;
        border: 1px solid #FAFBFC;
        -webkit-box-shadow: 0 8px 64px 0px rgb(21 42 65 / 5%);
        box-shadow: 0 8px 64px 0px rgb(21 42 65 / 5%);
        border-radius:16px;
        padding: 36px 36px;
        margin-top: 2rem;
        justify-content: space-evenly;
        ${props => props.theme.breakpoints.down("md")} {
            width:100%;

        }

    }
`;

export const FormControlNewsletter = styled(FormControl)`
    width:80%;
    padding-top: 12px;
    ${props => props.theme.breakpoints.down("md")} {
        width:100%;
    }
`;