import { NavBar, NavToolBar } from "../ui/navBar";
import { LogoImage, LogoContainer } from '../ui/logoImage';
import Link from '@mui/material/Link'; 
import { HeaderOrangeButton } from "../ui/button";
import { useNavBarStoreImplementation } from "../data/navBar/navBarStoreImplementation";
import { useNavBarViewModel } from "../controller/navBarViewModel";
import Container from "@mui/material/Container/Container";
import { useModalStoreImplementation } from "../data/modal/modalStoreImplementation";
import { useModalViewModel } from "../controller/modalViewModel";
import ModalSubscribeComponent from "../components/modal/modalSubscribeComponent";
import { useSubscribeStoreImplementation } from "../data/subscribe/subscribeStoreImplementation";
import { useSubscribeViewModel } from "../controller/subscribeViewModel";
import { usePostViewModel } from "../controller/postViewModel";
import { usePostStoreImplementation } from "../data/post/postStoreImplementation";
import { HeaderTitleTypography } from "../ui/typography";
import SocialShareComponent from "../components/socialShare";
import { useLocation } from 'react-router-dom'
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery/useMediaQuery";


const NavBarView = () => {
    const store = useNavBarStoreImplementation()
    const {
        onTop,
        detectOnTop
    } = useNavBarViewModel(store);

    const modalStore = useModalStoreImplementation();

    const {
        open,
        openModal, 
        closeModal
    } = useModalViewModel(modalStore)

    const storeSubscribe = useSubscribeStoreImplementation();

    const {
        subscribe,
        setEmail,
        setLastName,
        setName,
        clearSubscribeForm
    } = useSubscribeViewModel(storeSubscribe);

    const postStore = usePostStoreImplementation()
    const {
        postTitle,
        postSummary   
    } = usePostViewModel(postStore);

    let location = useLocation()
    window.addEventListener("scroll", detectOnTop! );

    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    return (
            <>
            <NavBar topshadow={onTop.toString()} position="fixed">
                <Container maxWidth="lg">
                    <NavToolBar>
                        <LogoContainer>
                            <Link href="/"> 
                                <LogoImage src='/images/logo-All-orange.svg' />
                            </Link>  
                        </LogoContainer>
                        <HeaderTitleTypography displaytext = { (window.scrollY >250).toString() } sx={{ flexGrow: 1}}> 
                            { !mdDown ? postTitle : '' } 
                        </ HeaderTitleTypography>
                        <SocialShareComponent
                            url = { window.location.href }
                            title = { postTitle }
                            summary = { postSummary }
                            visibility  = { location.pathname != '/' }
                         />
                        <HeaderOrangeButton onClick={ openModal }>Únete</HeaderOrangeButton>
                    </NavToolBar>
           <ModalSubscribeComponent
                open = { open }
                closeModal = { closeModal }
                callback = {{ clearFields: clearSubscribeForm, closeModal: closeModal }}
                store = {{
                    email: {
                        value: subscribe.email,
                        set: setEmail
                    },
                    name: {
                        value: subscribe.name,
                        set: setName
                    },
                    lastName:{
                        value: subscribe.lastName,
                        set: setLastName
                    }
                }}
            /> 
            </Container>
            </NavBar>
            </>
    )
};

export default NavBarView;