import { Post } from "../../domain/post/postEntity";
import { create } from '../../domain/post/postModel';



     async function getPost(id: string): Promise<Post | undefined>{
        const URL = process.env.REACT_APP_BASE_URL + id;
        const headers = new Headers();
   
    
        headers.append('Content-Type', 'application/json');
        const config: RequestInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors'
        }
        
        const request = new Request(URL, config);

        try {
        const response = await fetch(request);
     
        const data = await response.json();
        
        if(response.ok){
            return create(data);
        }else{
            return 
        }
    
        }catch(error){

            return
        }

      
   
    }
    
    export { getPost };