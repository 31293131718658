import Avatar from "@mui/material/Avatar";
import Skeleton from "@mui/material/Skeleton";
import { AvatarImage } from "../../ui/avatar";

export default function AvatarComponent(props: any){

    const { loading, alt, src } = props;

    return (
        <>
        { loading? 
            (
            <Skeleton variant="circular">
              <AvatarImage />
            </Skeleton>
            ):
            (
                <AvatarImage
                alt={ alt }
                src={ src }
             />
            ) }  
        </>
    )
}

