import Skeleton from "@mui/material/Skeleton";
import { PostViewTitleTypography } from "../../shared/ui/typography";

export default function PostTitleComponent(props: any){
    const { loading, title } = props;
    return(
        <>
        <PostViewTitleTypography>
            { loading?  <Skeleton animation='wave' sx={{ width:'100%' }} /> : title}
        </PostViewTitleTypography>
        </>
    )
}