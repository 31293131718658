
import { CardMainImage, CardContainer, MainImageSkeleton } from '../ui/cardImage';

export default function CardMainComponent(props: any){
    const { image, loading } = props;
    return(
        <>
        {
            loading?
            (
            <MainImageSkeleton 
            animation="wave" variant="rectangular" />
            ) : (
            <CardContainer>
                <CardMainImage
                    image={image}
                    {...{component:"img"}}
                    />

            </CardContainer>
            )
        }
        </>
    )
}