import layoutType from '../types/layout';
import { BodyContainer } from '../../ui/container';

const PublicLayout = ({children}: layoutType) => {
    return (
      <>
      <BodyContainer maxWidth="lg">
          {children}
      </BodyContainer>
      </>
    );
  };
  
  export default PublicLayout;
  