import Skeleton from "@mui/material/Skeleton";
import { PostListTitleTypography } from "../../ui/typography";

export default function PostListTitleComponent (props: any){

    const { loading, title } = props;

    return (
        <>
        <PostListTitleTypography >
            {loading? <Skeleton animation='wave' sx={{ width:'100px' }} /> : title }
        </PostListTitleTypography>
        </>
    )

}