import { styled } from '@mui/system';


export const LogoImage = styled('img')`
    &&{
        width: 100%;
        max-height: 50px;
        transform: translateY(25%);
    }
`;

export const LogoContainer = styled('div')`
    &&{
        position: relative;
        float: left;
        left: 0;
        z-index: 999;
        width: 12%;
        ${props => props.theme.breakpoints.down("md")} {
            width: 27%;
          }
    }`

export const LogoPuntoConomy =  styled('img')`
    &&{
    position: absolute;
    float: left;
    left: 0;
    z-index: 999;
    max-height: 5.5rem;
    transform: translateY(-50%);
    top: 50%;
    ${props => props.theme.breakpoints.down("md")} {
        max-height: 2.5rem;
        display:none
      }
}`