import debounce from "lodash.debounce";
import { Subscribe } from "../../domain/subscribe/subscribeEntity";
import { SubscribeStore } from "../../domain/subscribe/subscribeStore";

type UpdateSubscribeStore = Pick<
    SubscribeStore,
    "subscribe" | "updateSubscribe"
>

const debouncedTask = debounce((task) => Promise.resolve(task()), 50);

const updateSubscribeUseCase = (
    store: UpdateSubscribeStore,
    value: string,
    updateBy: (subscribe: Subscribe, value: string) => Subscribe
) => {
    const updatedSubscribe = store.subscribe ?
        updateBy(store.subscribe, value) :
        store.subscribe

    return  debouncedTask(() => store.updateSubscribe(updatedSubscribe));
}

export { updateSubscribeUseCase };
export type { UpdateSubscribeStore };