import React from "react";
import { ErrorStore } from "../domain/error/errorStore";
import { clearErrorUseCase } from "../useCases/error/clearErrorUseCase";
import { setErrorUseCase } from "../useCases/error/setErrorUseCase";

function useErrorViewModel(store: ErrorStore){

    const setError = React.useCallback(
        function(){
            setErrorUseCase({
                error: store.error,
                updateError: store.updateError
            })
        }
        , [store.error, store.updateError])

    const clearError = React.useCallback(
        function(){
            clearErrorUseCase({
                error: store.error,
                updateError: store.updateError
            })
        }
        ,[store.error, store.updateError])
    
    return {
        error: store.error,
        setError,
        clearError
    }
}

export { useErrorViewModel };