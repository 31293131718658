import { FormHelperText, IconButton, InputAdornment } from "@mui/material";
import { NewsletterInput } from "../../ui/inputText";
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import { useState } from "react";
export default function TextInputNewsletter(props: any){

    const { onChangeHandler, value, placeholder, submit } = props;
    const [ error, setError ] = useState(false);
    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(event.target.value);
        const errorValue  = validateEmail(event.target.value);
        setError(errorValue == null );
    }

    const validateEmail = (email: string) => {
        return String(email)
          .toLowerCase()
          .match(
            /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          );
      };

    return (
        <>        
        <NewsletterInput
        id="outlined-adornment-email"
        aria-describedby="outlined-email-helper-text"
        value = { value }
        onChange = { handleValueChange }
        placeholder= { placeholder }
        type = 'email'
        endAdornment={
        <InputAdornment position="end">
            <IconButton onClick={ submit } >
            <ArrowCircleUpRoundedIcon sx={{ color: '#FC730D', transform:'rotate(90deg)' }} />
            </IconButton>     
        </InputAdornment>
        }

      />
    <FormHelperText sx={{marginLeft: 0}} id="outlined-email-helper-text">
        { error ? 'Ingresa un email valido' : ''}
    </FormHelperText>
      </>

    )

}