import { getPost } from "./postServices";
import * as actionTypes from "./postActionTypes";

const getPostAction = (id: string, mainPost: boolean) => (dispatch: any) => {
    let loading = true;
    dispatch({ type: mainPost ? actionTypes.GET_MAIN_POST : actionTypes.GET_POST, loading });
    return getPost(id).then((post) => {

        loading = !loading;

        dispatch({ type: mainPost ? actionTypes.SET_MAIN_POST : actionTypes.SET_POST, post });
        
        dispatch({ type: mainPost ? actionTypes.GET_MAIN_POST : actionTypes.GET_POST, loading });
        
        return post
    });
};

const updatePostTitleAction = (title: string) => (dispatch: any) => {
    return dispatch({ type: actionTypes.UPDATE_POST_TITLE, title })
}

const updatePostTagAction = (tag: string) => (dispatch: any) => {
    return dispatch({ type: actionTypes.UPDATE_POST_TAG, tag })
}

const updatePostSummaryAction = (summary: string) => (dispatch: any) => {
    return dispatch({ type: actionTypes.UPDATE_POST_SUMMARY, summary });
}


export { getPostAction, updatePostTagAction, updatePostTitleAction, updatePostSummaryAction }