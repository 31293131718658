import React from "react";

import type { PostStore } from "../domain/post/postStore";
import { setPostTagUseCase } from "../useCases/post/setPostTagUseCase";
import { getPostUseCase } from "../../post/useCases/getPostUseCase";
import { setPostTitleUseCase } from "../useCases/post/setPostTitleUseCase";
import { clearPostTitleUseCase } from "../useCases/post/clearPostTitleUseCase";
import { setPostSummaryUseCase } from "../useCases/post/setPostSummaryUseCase";
import { clearPostSummaryUseCase } from "../useCases/post/clearPostSummaryUseCase";

function usePostViewModel(store: PostStore){

    const setPostTag = React.useCallback( (tag: string) =>
    setPostTagUseCase({
        updatePostTag: store.updatePostTag,
        postTag: store.postTag
        }, 
        tag
    ), [store.updatePostTag, store.postTag])
  
    const getPost =  React.useCallback( () =>  
            getPostUseCase(
                {
                loadPost: store.loadPost,
                postTag: store.postTag,
                mainPostTag: store.mainPostTag,
            }
            )
    , [store.loadPost, store.postTag])
    
    const setPostTitle = React.useCallback( (title: string) => 
            setPostTitleUseCase({
                postTitle: store.postTitle,
                updatePostTitle: store.updatePostTitle
            }, title), 
            [store.postTitle, store.updatePostTitle ]
    )

    const clearPostTitle = React.useCallback(
        function(){
            clearPostTitleUseCase({
                postTitle: store.postTitle,
                updatePostTitle: store.updatePostTag
            })
        }, [store.postTitle, store.updatePostTitle]
    )

    const setPostSummary = React.useCallback(
        (summary: string) =>
        setPostSummaryUseCase({
            postSummary: store.postSummary,
            updatePostSummary: store.updatePostSummary
        },
        summary
        ), [store.updatePostSummary, store.postSummary]
    )

    const clearPostSummary = React.useCallback(
        function(){
            clearPostSummaryUseCase({
                postSummary: store.postSummary,
                updatePostSummary: store.updatePostSummary
            })
        }, [store.postSummary, store.updatePostSummary]
    )
       
    return {
        post: store.post,
        getPost,
        setPostTag,
        clearPostTitle,
        setPostTitle,
        setPostSummary,
        clearPostSummary,
        postLoading: store.postLoading,
        postTag: store.postTag,
        postTitle: store.postTitle,
        postSummary: store.postSummary
    }
}

export { usePostViewModel };