
import { AvatarLabel, WriterInfoLabel } from "../../ui/avatar"
import AvatarComponent from "./avatar"
import DatePostComponent from "./datePost"
import WriterComponent from "./writer"

export default function PostInfoComponent(props: any){ 
    const { writerName, writerLastName, writerImage, date, estimatedTime, loading } = props

    return(
        <>
            <AvatarLabel>
                <AvatarComponent
                    loading = { loading }
                    alt={ writerName + ' ' + writerLastName }
                    src={ writerImage }
                />
             <WriterInfoLabel>
             <WriterComponent 
                loading = { loading }
                writerName = { writerName }
                writerLastName = { writerLastName }
             />
            <DatePostComponent 
                loading = { loading }
                date = { date }
                estimatedTime = { estimatedTime }
            />
            </WriterInfoLabel>
            </AvatarLabel>
        </>
    )
}