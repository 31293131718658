import CardMainComponent from "../../shared/components/cardMainImage";
import PostBreadCrumbComponent from "../../shared/components/postBreadCrumb";
import PostInfoComponent from "../../shared/components/postInfo/postInfo";
import PostTitleComponent from "./postTitle";

export default function HeaderMobileComponent (props: any){
    const { post, postLoading } = props;

    return (
        <>
              <PostBreadCrumbComponent
                loading = { postLoading || post === undefined }
                breadcrumb = { post?.breadCrum?.name }
              /> 
              <CardMainComponent
              loading = { postLoading || post === undefined }
              image = { post?.media?.headerImage?.dataUrl || '' }
              />
              <PostTitleComponent
                loading = { postLoading || post === undefined }
                title   = { post?.title }
              /> 
              <PostInfoComponent
              writerName={post?.writer?.name || ''}
              writerLastName={post?.writer?.last_name || ''}
              writerImage={post?.writer?.image?.dataUrl}
              estimatedTime={post?.estimatedTime?.toString() + ' min'}
              date={post?.internal?.updatedAt} 
              loading = { postLoading || post === undefined }
              />
            
            {/* <PostSubtitleComponent 
              loading = { postLoading || post === undefined }
              description = { post?.description }
            /> */}
          
        </>
    )
}
