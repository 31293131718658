import { Link } from 'react-router-dom';

import CardImageComponent from "../cardImage";
import PostInfoComponent from "../postInfo/postInfo";
import { Fragment } from 'react';

import { PostListContainer } from "../../ui/container";
import PostListTitleComponent from "./postListTitle";
import PostListSubtitleComponent from './postListSubtitle';
import PostListBreadCrumbComponent from './postListBreadCrumb';



export default function PostListComponent (props: any){

    const { thumbnail, writer , post, route , loading, onClick, key, breadcrumb } = props;
    
    return (
        <Fragment  key={key}>
        <PostListContainer >
            <Link to={ "/"+ breadcrumb?.toLowerCase().split(' ').join('_').replace(/[\u0300-\u036f]/g, "") +'/' + route}  key = { key } >
                <CardImageComponent
                image = {thumbnail.dataUrl}
                loading = { loading }
                />
            </Link>
            <PostListBreadCrumbComponent 
                loading = { loading }
                breadcrumb = { breadcrumb }
            />  
            <Link to={ "/"+ breadcrumb?.toLowerCase().split(' ').join('_').replace(/[\u0300-\u036f]/g, "") +'/' + route}  key = { key + '_1'} >
             <PostListTitleComponent
                loading = { loading }
                title   = { post?.title }
             />   
            </Link>
            <PostListSubtitleComponent 
                loading = { loading }
                description = { post?.description }
            />
            <PostInfoComponent
                writerName = { writer.name || '' } 
                writerLastName = {writer.last_name || '' }
                writerImage = { writer.image?.dataUrl }
                estimatedTime = { post?.estimatedTime.toString() + ' min'  }
                date = { post?.internal.updatedAt }   
                loading = { loading }               
                />
        </PostListContainer>
        </Fragment>
    )
}