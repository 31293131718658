import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { useSubscribeViewModel } from "../../controller/subscribeViewModel";
import { useSubscribeStoreImplementation } from "../../data/subscribe/subscribeStoreImplementation";
import { SubmitModalButton } from "../../ui/button";
import InputFieldComponent from "./textInputComponent";


export default function FormSubscribe(props: any){
      
    const { status, message, onValidated, store, callback } = props;

    const handleSubmit = (e: any) => {
        e.preventDefault();
        store.email?.value &&
        store.name?.value &&
        store.lastName?.value &&
        store.email?.value.indexOf("@") > -1 &&
        onValidated({
            "EMAIL": store.email?.value,
            "FNAME": store.name?.value,
            "LNAME": store.lastName?.value,
            "CAMPAIGN": "blog",
            "MMERGE4": navigator.userAgent || ''
        });
    }
    React.useEffect(()=>{
        if (status === 'success'){
            setTimeout(() => { callback.clearFields(); }, 500)
            setTimeout(() => { callback.closeModal(); }, 1000)
        }
    }, [ status ])
    return (
    <>
      <Box component="form" autoComplete="off" onSubmit={(e: any) => handleSubmit(e)}>
        <InputFieldComponent
            label="Nombre"
            type="Text"
            value = { store.name.value }
            placeholder = "Ingresa tu nombre"
            onChangeHandler = { store.name.set }
        />
        <InputFieldComponent
            label="Apellido"
            type="Text"
            value = { store.lastName.value }
            onChangeHandler = { store.lastName.set }
            placeholder = "Ingresa tu apellido"
        />
        <InputFieldComponent
            label="Email"
            type="Email"
            value = { store.email.value }
            onChangeHandler = { store.email.set }
            placeholder = "Ingresa tu email"
            required
        />
        <Typography>
        { message }
        </Typography>
        <SubmitModalButton type="submit" name="subscribe"> Aceptar </SubmitModalButton>
      </Box>  
    </>
    );
}


    

