import debounce from "lodash.debounce";
import { Subscribe } from "../../domain/subscribe/subscribeEntity";
import { SubscribeStore } from "../../domain/subscribe/subscribeStore";

type UpdateNewsletterStore = Pick<
    SubscribeStore,
    "newsletter" | "updateNewsletter"
>

const debouncedTask = debounce((task) => Promise.resolve(task()), 50);

const updateNewsletterUseCase = (
    store: UpdateNewsletterStore,
    value: string,
    updateBy: (newsletter: Subscribe, value: string) => Subscribe
) => {
    const updatedNewsletter = store.newsletter ?
        updateBy(store.newsletter, value) :
        store.newsletter

    return  debouncedTask(() => store.updateNewsletter(updatedNewsletter));
}

export { updateNewsletterUseCase };
export type { UpdateNewsletterStore };