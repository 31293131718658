
import debounce from "lodash.debounce";

import type { PostStore } from "../../domain/post/postStore";


type UpdatePostTitleStore = Pick<PostStore,
"updatePostTitle" | "postTitle"
>

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

const updatePosttitleUseCase = (
    store: UpdatePostTitleStore,
    title: string
) => {

    if (title === store.postTitle) return

    return debouncedTask(() => store.updatePostTitle(title));
}

export { updatePosttitleUseCase };
export type { UpdatePostTitleStore }