import type { AnyAction } from "redux";
import type { PostsStore } from "../../domain/posts/postsStore"
import * as actionTypes from "./postsActionTypes";

type PostsStoreState = Omit<PostsStore, "loadPosts">;

const INITIAL_STATE: PostsStoreState = {
    posts: undefined,
    mostReadPosts: undefined,
    mostReadPostsLoading: true,
    postsLoading: true
};

const postsReducer = (state: PostsStoreState = INITIAL_STATE, action: AnyAction) => {
    switch (action.type){
        case actionTypes.GET_POSTS:
            return {...state, postsLoading: action.loading};
        case actionTypes.GET_MOST_READ_POSTS:
            return {...state, mostReadPostsLoading: action.loading}
            case actionTypes.SET_POSTS:
                return {...state, posts: action.posts};
            case actionTypes.SET_MOST_READ_POSTS:
                return {...state, mostReadPosts: action.posts}
        default:
            return state;
    }
}

export { postsReducer };
export type { PostsStoreState };