import Box from "@mui/material/Box"
import PostLayout from "../layouts/public/postLayout"
import { SectionTitleTypography } from "../ui/typography"

const Error404View = () => {
    return (
        <PostLayout>
            <Box sx={{ textAlign: 'center', paddingTop: '24px' }}>
                <SectionTitleTypography>
                    Lo sentimos 😔, no encontramos la página 😢
                </SectionTitleTypography>
                <img src="/images/triste.svg" alt="404" style={{ width: '80%' }}/>
            </Box>
        </PostLayout>
    )
}
export { Error404View }