import React from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppRootState } from "../../../main/data/appStoreImplementation";
import { ModalStore } from "../../domain/modal/modalStore";
import { setModalAction } from "./modalActions";
import { ModalStoreState } from "./modalReducer";

const modalSelector = (state: AppRootState) => state.modal;

const useModalStoreImplementation = (): ModalStore => {
    const { open } = useSelector<
        AppRootState,
        ModalStoreState
    >(modalSelector);

    const dispatch = useDispatch();

    const updateModal = React.useCallback(
        (value: boolean) => setModalAction(value)(dispatch),
        [dispatch]
    )

    return {
        open,
        updateModal
    }

}

export { useModalStoreImplementation };