import React from 'react';

import type { PostsStore } from '../../shared/domain/posts/postsStore';
import { getPostsCarruselUseCase } from '../useCases/getPostsCarruselUseCase';

function useCarruselPostsViewModel(store: PostsStore, tag: string){
  

    const getCarruselPosts = React.useCallback(
        function() {
            getPostsCarruselUseCase({
                loadPosts: store.loadPosts
            });
        },
        [store]
    );

    return {
        posts: store.mostReadPosts?.filter( d => d.tag !== tag ).sort(() => Math.random() - 0.5).slice(0, 3),
        getCarruselPosts,
        postsLoading: store.mostReadPostsLoading
    };
}

export { useCarruselPostsViewModel };

