// export const firebaseConfig = {
//     apiKey: "AIzaSyAqRxmw2l9Kp27goZmWtvHxXCpnrP9GuUM",
//     authDomain: "conomy-blog.firebaseapp.com",
//     projectId: "conomy-blog",
//     storageBucket: "conomy-blog.appspot.com",
//     messagingSenderId: "107015528933",
//     appId: "1:107015528933:web:e89cf45efda32db11b0f0c",
//     measurementId: "G-WDFHSTNWHR"
//   };
  

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};