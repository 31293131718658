import React from "react";
import { ModalStore } from "../domain/modal/modalStore";
import { closeModalUseCase } from "../useCases/modal/closeModalUseCase";
import { openModalUseCase } from "../useCases/modal/openModalUseCase";


function useModalViewModel(store: ModalStore){

    const openModal = React.useCallback(
        function(){
            openModalUseCase({
                open: store.open,
                updateModal: store.updateModal
            })
        },
        [ store.open, store.updateModal ]
    )

    const closeModal = React.useCallback(
        function(){
            closeModalUseCase({
                open: store.open,
                updateModal:store.updateModal
            })
        },
        [store.open, store.updateModal]
    )

    return {
        open: store.open,
        openModal,
        closeModal
    }
}

export { useModalViewModel };