import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/system';

export const AvatarLabel = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 0;
`;

export const WriterInfoLabel = styled('div')`
    flex-direction: column;
    margin: 2px 0 0 6px;
`;

export const AvatarImage = styled(Avatar)`
    margin-right: 14px;
`;