import Skeleton from "@mui/material/Skeleton";
import { BreadCrumbTypography } from "../../ui/typography";

export default function PostListBreadCrumbComponent (props: any){

    const { loading, breadcrumb } = props;

    return (
        <>
        <BreadCrumbTypography >
            {loading? <Skeleton animation='wave' sx={{ width:'100px' }} /> : breadcrumb }
        </BreadCrumbTypography>
        </>
    )

}