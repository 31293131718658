
import { LinkedinShareButton, WhatsappShareButton } from "react-share";
import WhatsappRoundedIcon from '@mui/icons-material/WhatsappRounded';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import IosShareIcon from '@mui/icons-material/IosShare';
import IconButton from "@mui/material/IconButton/IconButton";
import { SocialShareContainer } from "../ui/container";
import { ShareTypography } from "../ui/typography";
import Tooltip from "@mui/material/Tooltip";
export default function SocialShareComponent(props: any){

    
    const { url, title, summary, visibility, className } = props;

    return (

        <>
        <SocialShareContainer className = { className? className : '' } visibility={ visibility.toString() }>
        <ShareTypography>
            Compartir: 
        </ShareTypography>
         <IconButton component="span">
        <LinkedinShareButton
            url={url}
            title={title}
            summary={summary}
            style={{ lineHeight:'0' }}
        >
            <LinkedInIcon fontSize="small" />
        </LinkedinShareButton>
        </IconButton >
        <IconButton component="span">
            <WhatsappShareButton
                url={url}
                title={title}
                style={{ lineHeight:'0' }}
            >
            <WhatsappRoundedIcon fontSize="small" />
            </WhatsappShareButton>
        </IconButton>
        <Tooltip title="Copiar">
            <IconButton onClick={() =>  navigator.clipboard.writeText( url )} >
                <IosShareIcon fontSize="small"  />
            </IconButton>
        </Tooltip>
        </SocialShareContainer>
        </>
        
        
    )
}