import Skeleton from "@mui/material/Skeleton";
import ReactMarkdown from 'react-markdown';

export default function PostBodyComponent (props: any){
    const { loading, body } = props;

    return (
        <>
        { loading?
        (
            <Skeleton variant="rectangular" width="100%" height="700px" sx={{ marginTop: '1rem' }}/>
        ):
        (
            <ReactMarkdown className='text-body'>
            { body! }
            </ReactMarkdown>
        )
        }
        </>
    )

}