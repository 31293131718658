import { PostList } from "../../domain/posts/postsEntity";
import { create } from "../../domain/posts/postsModel"

async function getPosts(): Promise<Array<PostList> | boolean >{
    const URL: string = process.env.REACT_APP_BASE_URL!;
    const headers = new Headers();
    const published_post: boolean = !!+process.env.PUBLISHED_POST!;
    headers.append('Content-Type', 'application/json');
    const config: RequestInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors'
    }
    const request = new Request(URL, config);

    const response = await fetch(request);
    const data = await response.json();

    try {
        const response = await fetch(request);
     
        const data = await response.json();

    if(response.ok){
        if (true){
            return create(data.data)
        }else{
            const filtered_post = data.data.filter( (d: any) => {
                return d.published === true});
            return create(filtered_post);
        }
    }else{
        
        return false
    }

    }catch(error){

        return false 
    }
}


export { getPosts };