import React from "react";
import type { NavBarStore } from "../domain/navBar/navBarStore";
import { detectOnTopUseCase } from "../useCases/navBar/detectOnTopUseCase";

function useNavBarViewModel(store: NavBarStore){
    
    const detectOnTop = React.useCallback(
        function(){
            detectOnTopUseCase({
                onTop: store.onTop,
                updateOnTop: store.updateOnTop,
                setOnTop: store.setOnTop
            });
        },
        [store.onTop, store.setOnTop, store.updateOnTop]
    )
    return {
        onTop: store.onTop,
        detectOnTop
    }
}

export { useNavBarViewModel }