import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { NewsletterInput } from "../../ui/inputText";

import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import IconButton from "@mui/material/IconButton/IconButton";
import { FormControlNewsletter } from "../../ui/newsletter";
import React from "react";
import TextInputNewsletter from "./textInputNewsletter";
import { Box } from "@mui/material";

export default function FormNewsletter(props: any){

    const { status, message, onValidated, store, callback } = props;

  
    const handleSubmit = (e: any) => {
        e.preventDefault();
        store.email?.value &&
        store.email?.value.indexOf("@") > -1 &&
        onValidated({
            "EMAIL": store.email?.value,
            "MMERGE4": navigator.userAgent || '',
            "MMERGE5":'newsletter'
        });
    }
    
    React.useEffect(()=>{
        if (status === 'success'){
            setTimeout(() => { callback.clearFields(); }, 500)
            setTimeout(() => { props.message = '' }, 1000)
        }
    }, [ status ])



    return (
        <Box component="form" autoComplete="off">
        <FormControlNewsletter variant="outlined">
            <TextInputNewsletter
                value = { store.email.value }
                onChangeHandler = { store.email.set }
                placeholder = "Ingresa tu correo"
                submit = { handleSubmit }

            />
            { message }
        </FormControlNewsletter>    
      </Box>  
    )
}