import debounce from "lodash.debounce";
import type { PostsStore } from "../../domain/posts/postsStore";

type GetPostsStore = Pick<PostsStore, "loadPosts">;

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

const getPostsUseCase = (
    store: GetPostsStore, 
    homePost: boolean = true) => {
        return debouncedTask(() => store.loadPosts(homePost));
};

export type { GetPostsStore } 
export { getPostsUseCase };