import { IndexKind } from "typescript";
import type { Subscribe } from "./subscribeEntity"

const setEmail = (subscribe: Subscribe, value: string) => ({
    ...subscribe,
    email: value
});

const setName = (subscribe: Subscribe, value: string) => ({
    ...subscribe,
    name: value
})

const setLastName = (subscribe: Subscribe, value: string) => ({
    ...subscribe,
    lastName: value
})

const updateAllValues = (subscribe: Subscribe, value: string) => {

    const objKeys = Object.keys(subscribe) as Array<keyof Subscribe>
    objKeys.forEach((k: keyof Subscribe) => {
        subscribe[k!] = value
    });

    return subscribe;
};

export { setEmail, setName, setLastName, updateAllValues }