
import { FormHelperText, InputAdornment, OutlinedInput } from '@mui/material';
import Box from '@mui/material/Box';

import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { NewsLetterImage } from '../../ui/cardImage';

import { CardNewsLetter } from '../../ui/newsletter';
import { NewsletterTypography } from '../../ui/typography';
import MailchimpComponent from '../mailchimp/mailchimpContainer';
import FormNewsletter from './formNewsletter';


export default function NewsletterComponent(props: any) {
  const { store, callback } = props;
  const url = "https://conomyhq.us19.list-manage.com/subscribe/post?u=913fb462d6cd9061f4d515557&id=b78d0cb936";
  return (

    <CardNewsLetter>
      <NewsLetterImage
        image="/images/wallet.svg"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '0 1 auto' }}> 
        <NewsletterTypography>
        🗞 Ingresa tu correo para recibir noticias.  
        </NewsletterTypography>
        <MailchimpComponent
          postUrl = {url }
          form = { FormNewsletter }
          store = { store }
          callback = { callback }
        />

        </CardContent>
        </Box>
    </CardNewsLetter>
  )
}
