
import debounce from "lodash.debounce";

import type { PostStore } from "../../domain/post/postStore";


type UpdatePostSummaryStore = Pick<PostStore,
"updatePostSummary" | "postSummary"
>

const debouncedTask = debounce((task) => Promise.resolve(task()), 500);

const updatePostSummaryUseCase = (
    store: UpdatePostSummaryStore,
    summary: string
) => {

    if (summary === store.postSummary) return

    return debouncedTask(() => store.updatePostSummary(summary));
}

export { updatePostSummaryUseCase };
export type { UpdatePostSummaryStore }