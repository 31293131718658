
import Dialog from '@mui/material/Dialog';
import { ModalImage } from '../../ui/cardImage';
import { ModalColumns, ModalContainer } from '../../ui/container';
import { ModalTitleTypography } from '../../ui/typography';
import FormSubscribe from '../form/formSubscribeComponent';
import MailchimpComponent from '../mailchimp/mailchimpContainer';


export default function ModalSubscribeComponent(props: any) {

  const { open, closeModal, store, callback  } = props;
  const url = "https://conomyhq.us19.list-manage.com/subscribe/post?u=913fb462d6cd9061f4d515557&id=b78d0cb936";
  return (
    <Dialog
        open={ open }
        onClose={ closeModal }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth='md'
    >  
      <ModalColumns>
      <ModalContainer>
      <ModalTitleTypography>
      😍 Únete a la lista de espera para nuestra App y recibe las novedades de nuestro blog.
        </ModalTitleTypography>
        <MailchimpComponent
          postUrl = { url } 
          form = { FormSubscribe }
          store = { store }
          callback = { callback }
        />
      </ModalContainer>
        <ModalImage
        image="/images/amigos.svg"
        />
      </ModalColumns>
    </Dialog>
  );
}