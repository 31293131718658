import React from 'react';

import type { PostStore } from '../../shared/domain/post/postStore';

import { getMainPostUseCase } from '../useCases/getMainPostUseCase';

function useMainPostViewModel(store: PostStore){

    const getMainPost = React.useCallback(
        function(){
            getMainPostUseCase({
                loadPost: store.loadPost,
                mainPostTag: store.mainPostTag,
                postTag: store.postTag
            });
        },
        [store.loadPost, store.mainPostTag]
    );
    return {
        mainPost: store.mainPost,
        mainPostLoading: store.mainPostLoading,
        getMainPost
    };
};

export { useMainPostViewModel };