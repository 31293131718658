
import Skeleton from '@mui/material/Skeleton';
import { MainPostTitleTypography } from '../../../shared/ui/typography';

export default function MainPostTitleComponent(props: any) {
    const { loading, title } = props;
    return(
        <>
        <MainPostTitleTypography>
                { loading ? <Skeleton sx={{ width:'100%', height:'100%' }}/> : title }
        </MainPostTitleTypography>
        </>
    )
}