import { getPosts } from "./postsService";
import * as actionTypes from "./postsActionTypes";


const getPostsAction = (homePost: boolean) => (dispatch: any) =>{ 
    let loading = true;

    dispatch({ type: homePost ? actionTypes.GET_POSTS: actionTypes.GET_MOST_READ_POSTS, loading });

    return getPosts().then((posts) => {
        loading = !loading;

        dispatch({ type: homePost ? actionTypes.SET_POSTS: actionTypes.SET_MOST_READ_POSTS, posts });

        dispatch({ type: homePost ? actionTypes.GET_POSTS: actionTypes.GET_MOST_READ_POSTS, loading });

        return posts
    });
};

export { getPostsAction };