import Helmet from 'react-helmet';

export default function SEOComponent(props: any){
    const { title, content, url, image } = props;
    return (
        <Helmet>
        <title>{ title }</title>
            <meta name="description"
                content= { content } />
            <meta name="keywords" content="finanzas personales, finanzas, app, fintech, educacion financiera, ahorro, superpoderes, banco, todas en una, presupuesto, wallet, digital, billetera, educación financiera, ahorro, deudas, plata, inclusión, prepago, inversión" />

            <link rel="canonical" href={ url } />

            <meta property="og:title" content={ title } />
            <meta property="og:description"
                content= { content } />
            <meta property="og:type" content="website" />
            <meta property="og:image" content= { image }/>
            <meta property="og:url" content={ url } />
            <meta property="og:site_name" content="Punto Conomy" />


            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@conomyhq" />
            <meta name="twitter:title" content= { title } />
            <meta name="twitter:description"
                content=  { content }  />
        </Helmet>
    )
}

