import type { AnyAction } from "redux";
import type { PostStore } from "../../domain/post/postStore";
import * as actionTypes from "./postActionTypes";

type PostStoreState = Omit<PostStore, 
"loadPost" | "updatePostTag" | "updatePostTitle" | "updatePostSummary" >

const INITIAL_STATE: PostStoreState = {
    post:undefined,
    mainPost: undefined,
    mainPostLoading: true,
    postLoading: true,
    postTag: undefined,
    postTitle: '',
    postSummary: '',
    mainPostTag: process.env.REACT_APP_MAIN_POST_TAG!,
};

const postReducer = (state: PostStoreState = INITIAL_STATE, action: AnyAction) => {
    switch (action.type){
        case actionTypes.SET_POST:
            return {...state, post: action.post };
        case actionTypes.SET_MAIN_POST:
            return {...state, mainPost: action.post };
        case actionTypes.GET_POST:
            return {...state, postLoading: action.loading };
        case actionTypes.GET_MAIN_POST:
            return {...state, mainPostLoading: action.loading };
        case actionTypes.UPDATE_POST_TITLE:
            return {...state, postTitle: action.title  }
        case actionTypes.UPDATE_POST_TAG:
            return {...state, postTag: action.tag };
        case actionTypes.UPDATE_POST_SUMMARY:
            return {...state, postSummary: action.summary }
        default:
            return state;
        
    }
}

export { postReducer };
export type { PostStoreState };