import CardMedia from '@mui/material/CardMedia';
import { Container } from '@mui/material';
import { styled } from '@mui/system';
import Skeleton from '@mui/material/Skeleton';

export const CardImage = styled(CardMedia)`
        border: 1px solid #FAFBFC;
        box-shadow: 0px 8px 64px 0px rgb(21 42 65 / 0.05);
        border-radius: 16px;
        width:100%;
        height: 250px;
        ${props => props.theme.breakpoints.down("md")} {
            height: 250px;
        }

`;

export const CardContainer = styled(Container)`
    padding-left: 0 !important;
    padding-right: 0 !important;
    ${props => props.theme.breakpoints.down("md")} {
        height: 250px;
    }
`;

export const CardMainImage = styled(CardMedia)`
    border: 1px solid #FAFBFC;
    box-shadow: 0px 8px 64px 0px rgb(21 42 65 / 0.05);
    border-radius: 8px;
    width:100%;
    height: 350px;
    ${props => props.theme.breakpoints.down("md")} {
        position: absolute;
        border-radius: 0px;
        left: 0;
        height: 250px;
    }
`;

export const ImageSkeleton = styled(Skeleton)`
    height: 250px;
    ${props => props.theme.breakpoints.down("md")} {
        height: 250px;
    }
`;

export const MainImageSkeleton = styled(Skeleton)`
height: 350px;
${props => props.theme.breakpoints.down("md")} {
    height: 250px;
    position: absolute;
    border-radius: 0px;
    left: 0;
    height: 250px;
}
`;


export const ModalImage = styled(CardMedia)`
    width: 110%;
    background-size: 90%;
    background-color: #FFFAF4;
    ${props => props.theme.breakpoints.down("md")} {
        display: none;
    }
    border-left: 1px solid #FAFBFC;
    -webkit-box-shadow: 0 8px 64px 0px rgb(21 42 65 / 5%);
    box-shadow: 0 8px 64px 0px rgb(21 42 65 / 5%);

`

export const NewsLetterImage = styled(CardMedia)`
    width: 22%;
    background-size: contain;
    ${props => props.theme.breakpoints.down("md")} {
        display: none;
    }

`