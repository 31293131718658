import Skeleton from "@mui/material/Skeleton";
import { WriterTypography } from "../../ui/typography";

export default function WriterComponent(props: any){
    const { loading ,writerName, writerLastName } = props;
        return(
            <>
            <WriterTypography>
                { loading ? <Skeleton animation='wave' sx={{ width:'100px' }}/> : writerName + ' ' + writerLastName }
            </WriterTypography>
            </>
        )
    
}