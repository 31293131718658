import ReactMarkdown from 'react-markdown';
import Skeleton from "@mui/material/Skeleton";

export default function PostListSubtitleComponent (props: any){

    const { loading, description } = props;

    return (
        <>
        {loading? (
                <Skeleton variant="rectangular" width="100%" height="50px" sx={{ marginTop: '1rem' }}/>
            ):(
                <ReactMarkdown className="subtitle-postlist">
                    { description }
                </ReactMarkdown>
            )}
        </>
    )
}