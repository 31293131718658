import React from "react";
import { useDispatch, useSelector } from "react-redux";
import type { PostStoreState } from "./postReducer";
import {
    getPostAction,
    updatePostSummaryAction,
    updatePostTagAction,
    updatePostTitleAction
} from "./postActions";

import { AppRootState } from "../../../main/data/appStoreImplementation";

import { PostStore } from "../../domain/post/postStore";


const postSelector = (state: AppRootState) => state.post;

const usePostStoreImplementation = (): PostStore => {
    const { 
        post, 
        mainPost, 
        postLoading ,
        mainPostLoading,
        mainPostTag, 
        postTag,
        postTitle,
        postSummary,
        } = useSelector<
        AppRootState,
        PostStoreState
    >(postSelector);

    const dispatch = useDispatch();

    
    const loadPost = React.useCallback(
        (id: string, mainPost: boolean) => getPostAction(id, mainPost)(dispatch),
        [dispatch]
    )
    
    const updatePostTag = React.useCallback(
        (tag: string) => updatePostTagAction(tag)(dispatch),
        [dispatch]
    )

    const updatePostTitle = React.useCallback(
        (title: string) => updatePostTitleAction(title)(dispatch),
        [dispatch]
    )

    const updatePostSummary = React.useCallback(
        (summary: string) => updatePostSummaryAction(summary)(dispatch),
        [dispatch]
    )
   



    return {
        post,
        mainPost,
        mainPostTag,
        postLoading, 
        mainPostLoading,
        postTag,
        postTitle,
        postSummary,
        updatePostSummary,
        loadPost,
        updatePostTag,
        updatePostTitle
    }
};

export { usePostStoreImplementation };