import Grid from '@mui/material/Grid';
import React from "react";
import { useParams } from "react-router-dom";
import { usePostStoreImplementation } from "../../shared/data/post/postStoreImplementation";
import PostLayout from "../../shared/layouts/public/postLayout";
import { usePostViewModel } from "../../shared/controller/postViewModel";
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import { useCarruselPostsViewModel } from '../controller/postsCarruselViewModel';
import { usePostsStoreImplementation } from '../../shared/data/posts/postsStoreImplementation';
import PostBodyComponent from '../component/postBody';
import PostsSectionComponent from '../../shared/components/PostsSectionComponent';
import { useSubscribeStoreImplementation } from '../../shared/data/subscribe/subscribeStoreImplementation';
import { useSubscribeViewModel } from '../../shared/controller/subscribeViewModel';
import NewsletterComponent from '../../shared/components/newsletter/newsletter';
import SocialShareComponent from '../../shared/components/socialShare';
import { useErrorViewModel } from '../../shared/controller/errorViewModel';
import { useErrorStoreImplementation } from '../../shared/data/error/errorStoreImplementation';
import SEOComponent from '../../shared/components/seo';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import HeaderComponent from '../component/header';
import HeaderMobileComponent from '../component/headerMobile';

const PostView = () => {
  
  const postStore = usePostStoreImplementation();

  const carruselStore = usePostsStoreImplementation();

  const theme = useTheme();
  const mobileHeader = useMediaQuery(theme.breakpoints.up('md'));
  
  const params = useParams();
  const tag = params.tag || '';
  
  const {
    post,
    getPost,
    setPostTag,
    postTag,
    postLoading,
    clearPostTitle,
    setPostTitle,
    setPostSummary,
    clearPostSummary,
    postTitle,
    postSummary,
  } = usePostViewModel(postStore);

  const {
    posts,
    getCarruselPosts,
    postsLoading,
  } = useCarruselPostsViewModel(carruselStore, tag);

  const newsLetterStore = useSubscribeStoreImplementation();

  const {
      clearNewsletterForm,
      setNewsletterEmail,
      newsletter
  } = useSubscribeViewModel(newsLetterStore);

  const errorStore = useErrorStoreImplementation();
  const {
    error,
    setError,
    clearError
  } = useErrorViewModel(errorStore);


  React.useEffect(() => {
    setPostTag(tag);
    getCarruselPosts();
    clearPostTitle();
    clearPostSummary();
  },[tag]);

  React.useEffect(() => {
    getPost();
    window.scrollTo(0, 0);
  },[postTag])
  
  React.useEffect(() => {
    setPostTitle(post?.title!);
    setPostSummary(post?.description!);
    if ((!postLoading && !postsLoading) && post === undefined ) {
      setError();
    } 
  }, [postLoading, postsLoading])

    return (
      <>
      <SEOComponent
        title = { post?.title }
        content = { post?.description }
        url = { window.location.href }
        image = { post?.media?.headerImage?.dataUrl }
      />
      <PostLayout>
        <Grid container spacing={2}>
          <Grid item xs={12}>


          {mobileHeader ? 
          <HeaderComponent 
                post = { post }
                postLoading = { postLoading }
              /> :
          <HeaderMobileComponent 
                post = { post }
                postLoading = { postLoading }
              />
          }
            

            <PostBodyComponent
              loading = { postLoading || post === undefined }
              body = { post?.content }
            />

            <SocialShareComponent
              url = { window.location.href }
              title = { post?.title }
              summary = { post?.description }
              visibility  = { 'true' }
              className = 'social-share-post'
            />
          </Grid>
        </Grid>

      </PostLayout>
      <Container maxWidth="lg" >
      <NewsletterComponent
          store = {{email:{value: newsletter.email, set: setNewsletterEmail}}}
          callback = {{clearFields: clearNewsletterForm}}
        />  

     <Divider sx={{ marginTop: '3em', marginBottom: '3em' }} />

      <PostsSectionComponent
        loading = { postsLoading || posts === undefined }
        posts = { posts }
        title = " Te recomendamos leer 👻"
      />    
      </Container>
      </>
    );
  };
  
  export default PostView;