import Typography from '@mui/material/Typography';

import { styled } from '@mui/system';

export const TitleContainer = styled('div')`
   && {
     margin-bottom: 24px;
     position:relative;
     display: inline-flex;
     align-items: baseline;
     ${props => props.theme.breakpoints.down("md")} {
        line-height: 3rem;
        padding-left:0
      }
   }
`;
export const ConomyTypography = styled(Typography)`
   &&{
       font-family: 'Baloo 2';
       font-size: 5.5rem;
       font-weight: 600;
       ${props => props.theme.breakpoints.down("md")} {
        font-size: 3.3rem;
     }
   }
`;

export const DotTypography = styled(Typography)`
   &&{
    font-family: 'Baloo 2';
    font-size: 5.5rem;
    font-weight: 600;
    font-style: italic;
     ${props => props.theme.breakpoints.down("md")} {
        font-size: 3.3rem;
     }
   }
`;


export const DotConomyTypography = styled(Typography)`
&&{
    font-family: 'Baloo 2';
    font-size: 6.5rem;
    font-weight: 900;
    color: #FC730D;
    transform: translateY(0.5rem);
    ${props => props.theme.breakpoints.down("md")} {
        font-size: 4rem;
     }
}
`;